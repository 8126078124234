
* {
  box-sizing: border-box;
}

.react-hot-toast {
  font-size: 1rem;
  // color: $body-color;
  letter-spacing: 0.14px;
  box-shadow: 0px 4px 10px -4px rgb(58 53 65 / 60%);
  // border-radius: $border-radius;
}

body {
  font-family: Inter,sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #D3D0D8;
    border-radius: 6px;
  }
}

.react-hot-toast {
  max-width: 550px;
  div[role='status'] {
    word-break: break-word;
  }
}

div {
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #D3D0D8;
    border-radius: 6px;
  }
}

div[style*="bottom: 0px; justify-content: flex-end;"]:has(> .react-hot-toast) {
  bottom: unset !important;
  top: calc(100vh - 118px);
  padding-right: 98px;
}

.app-content {
  margin-left: 244px !important;

  @media (max-width: 1200px) {
    margin-left: unset !important;
  }
}